import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import "../../../Styles/LpaStoreAnalysisStyles.css";
import { lpaCashOfficeDetailsState } from '../../../Recoil/LpaCashOfficeDetailsState';
import { auditCodeAtom } from '../../../Recoil/auditCodeAtom';

const LpaCashOfficeFeedback = () => {
  const setLpaCashOfficeDetails = useSetRecoilState(lpaCashOfficeDetailsState);
  const lpaCashOfficeDetails = useRecoilValue(lpaCashOfficeDetailsState);
  const auditCodeValue = useRecoilValue(auditCodeAtom);

  const [isLoading, setIsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = useState(false);

  useEffect(() => {
    // Fetch data from the API and update LPAQuestions in the atom
    const fetchData = async () => {
      try {
        const response = await axios.get('https://myworxit.co.za/api/auditsdc/cashOfficeSectionTableDetails.php');
        const newData = response.data.records;
        // Update the LPAQuestions in the atom if newData is an array
        if (Array.isArray(newData)) {
          setLpaCashOfficeDetails(newData);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData()
      .then(() => setIsDataReady(true)) // Set isDataReady to true when the data is available
      .catch(() => setIsDataReady(true));
  }, [setLpaCashOfficeDetails]);

  // Check if lpaAdminDetails is an array before using map()
  if (!Array.isArray(lpaCashOfficeDetails)) {
    return <div>Loading...</div>; // or return an empty component if desired
  }

  // Filter lpaAdminDetails based on the auditCode
  const filteredCashOfficeDetails = lpaCashOfficeDetails.filter(details => details.auditCode === auditCodeValue.auditCode);
  
  if (!isDataReady) {
    return <div>Loading...</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  
    return (
        <div className='questionContainer'>
            <div className='titleHeader'>
                <h4>LPA Store Analysis : Cash Office</h4>
            </div>
            <table>
                <thead>
                    <tr>
                        {/* <th className="typeWidth">Type</th> */}
                        <th className="scoreTypeColumnWidth">Type</th>
                        <th className="answersColumnWidth">LPA Questions</th>
                        <th className="questionsColumnWidth">Observation/Responses</th>
                        <th className="notesColumnWidth">Notes </th>
                    </tr>
                </thead>
                <tbody>
                    {filteredCashOfficeDetails.map((question, index) => (
                        <tr key={index}>
                        <td className="scoreTypeColumnWidth">
                            {question.scores === 'Pass' ? (
                                <div className='passCircle'></div>
                            ) : question.scores === 'Fail' ? (
                                <div className='failCircle'></div>
                            ) : (
                                <div className='naCircle'></div>
                            )}
                        </td>
                        <td className="questionsColumnWidth">{question.question}</td>
                        <td className="answersColumnWidth">{question.responses} </td>
                        <td className="notesColumnWidth">{question.notes} </td>
                        </tr>
                    ))}
                    </tbody>
            </table>  
            <div className='titleFooter'>
                {filteredCashOfficeDetails.length > 0 && (
                    <h4>Cash Office LPA Score = {filteredCashOfficeDetails[0].totalScore}%</h4>
                )}
            </div>
        </div>
   
    )
}

export default LpaCashOfficeFeedback;